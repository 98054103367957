export const FRONT_URL = process.env.VUE_APP_FRONT_URL || "https://pro.karnott.fr";

export const HARDWARE_USER_ROLE = "HARDWARE";
export const CARE_USER_ROLE = "CARE";
export const SALE_USER_ROLE = "SALE";
export const ADMIN_USER_ROLE = "ADMIN";
export const USER_ROLE = "USER";
export const DRIVER_USER_ROLE = "DRIVER";
export const SEASONAL_DRIVER_USER_ROLE = "SEASONAL_DRIVER";
export const IMPLEMENT_MANAGER_USER_ROLE = "IMPLEMENT_MANAGER";
export const CLUSTER_ROLES = [
  ADMIN_USER_ROLE,
  DRIVER_USER_ROLE,
  SEASONAL_DRIVER_USER_ROLE,
  IMPLEMENT_MANAGER_USER_ROLE
];
export const FARM_CLUSTER_TYPE = "FARM";
export const CONCESSIONNAIRE_CLUSTER_TYPE = "CONCESSIONNAIRE";
export const CUMA_CLUSTER_TYPE = "CUMA";
export const ETA_CLUSTER_TYPE = "ETA";
export const CLUSTER_TYPES = [FARM_CLUSTER_TYPE, ETA_CLUSTER_TYPE, CUMA_CLUSTER_TYPE, CONCESSIONNAIRE_CLUSTER_TYPE];
export const CLUSTER_TYPE_LABELS_MAPPING = {
  [FARM_CLUSTER_TYPE]: "Ferme",
  [CUMA_CLUSTER_TYPE]: "CUMA",
  [ETA_CLUSTER_TYPE]: "ETA",
  [CONCESSIONNAIRE_CLUSTER_TYPE]: "CONCESSIONNAIRE"
};

export const FOLLOW_SHIPPING_STATE = "FOLLOW_SHIPPING";

export const OBSERVATION_CATEGORY_PLUGIN = "observation_category";
export const USER_ROLE_MANAGEMENT_PLUGIN = "user_role_management";
export const MOBILE_APPLICATION_PLUGIN = "mobile_application";
export const SMAG_FARMER_PLUGIN = "smag_farmer";
export const TRACEABILITY_MANAGEMENT_PLUGIN = "traceability_management";
export const MYCUMA_PLUGIN = "mycuma";
export const WORKSITE_REPORT_PLUGIN = "worksite_report";
export const CONTACT_SEC_PLUGIN = "contact_sec";
export const DATA_ACCESS_INDEFINITELY_PLUGIN = "data_access_indefinitely";
export const BIG_ACCOUNT_PLUGIN = "big_account";
export const LAVILOG_PLUGIN = "lavilog";
export const LEA_PLUGIN = "lea";
export const SATELLITE_VEGETATION_PLUGIN = "satellite_vegetation";
export const DRIVER_REPORT_PLUGIN = "driver_report";
export const SHOW_SPEED_PLUGIN = "show_speed";
export const OBSERVATION_PLUGIN = "observation";
export const DATA_ACCESS_24_MONTHS_PLUGIN = "data_access_24_months";
export const DATA_ACCESS_12_MONTHS_PLUGIN = "data_access_12_months";
export const PDF_EXPORT_PLUGIN = "pdf_export";
export const COUNT_BALE_PLUGIN = "count_bale";
export const CROP_MANAGEMENT_PLUGIN = "crop_management";
export const ISAETA_PLUGIN = "isaeta";
export const EBP_PLUGIN = "ebp";
export const AUTOMATED_SHARE_REPORT_PLUGIN = "automated_share_report";
export const SHARE_EQUIPMENT_SESSION_PLUGIN = "share_equipment_session";
export const MOBILE_OFFLINE_PLUGIN = "mobile_offline";
export const IGN_ACCESS_PLUGIN = "ign_access";
export const DO_NOT_IGNORE_PARCELS_PLUGIN = "do_not_ignore_parcels";
export const LATENCE_BETWEEN_FIELD_WORK_PLUGIN = "latence_between_field_work";
export const RENT_FIELD_PLUGIN = "rent_field";
export const EDIT_SESSION_PLUGIN = "edit_session";
export const KARNOTT_OUTIL_PLUGIN = "karnott_outil";
export const REAL_TIME_PLUGIN = "real_time";
export const PRINT_MAPS_PLUGIN = "printing_maps";
export const GO_TO_OBSERVATION_PLUGIN = "go_to_observation";
export const VITICULTURAL_CADASTRAL_REFERENCE_PLUGIN = "viticultural_cadastral_reference";
export const IGN_TILES_PLUGIN = "ign_tiles";
export const PHYTO_PLUGIN = "phyto";
export const RPG_ILOT_PLUGIN = "rpg_ilot";
export const LOCK_GEOLOCATION_PLUGIN = "lock_geolocation";
export const DISABLE_DRIVER_GEOLOC_PLUGIN = "disable_driver_geoloc";
export const WORKED_AREA_PLUGIN = "worked_area";
export const PLUGIN_LABELS = {
  [OBSERVATION_CATEGORY_PLUGIN]: `categories d'observation`,
  [USER_ROLE_MANAGEMENT_PLUGIN]: `gestion des roles`,
  [MOBILE_APPLICATION_PLUGIN]: `app mobile`,
  [SMAG_FARMER_PLUGIN]: `smag farmer`,
  [MYCUMA_PLUGIN]: `my cuma`,
  [LAVILOG_PLUGIN]: `lavilog`,
  [LEA_PLUGIN]: `lea`,
  [OBSERVATION_PLUGIN]: `observations`,
  [DATA_ACCESS_24_MONTHS_PLUGIN]: `accès aux données 2 ans`,
  [DATA_ACCESS_12_MONTHS_PLUGIN]: `accès aux données 1 an`,
  [DATA_ACCESS_INDEFINITELY_PLUGIN]: `accès aux données illimité`,
  [CROP_MANAGEMENT_PLUGIN]: `gestion des cultures`,
  [ISAETA_PLUGIN]: `isaeta`,
  [EBP_PLUGIN]: `ebp`,
  [IGN_ACCESS_PLUGIN]: `accès à la base IGN`,
  [DO_NOT_IGNORE_PARCELS_PLUGIN]: `n'ignorer aucune parcelle`,
  [RENT_FIELD_PLUGIN]: `prêt de parcelle`,
  [KARNOTT_OUTIL_PLUGIN]: `attelage`,
  [SHOW_SPEED_PLUGIN]: `affichage des vitesses`,
  [SHARE_EQUIPMENT_SESSION_PLUGIN]: `partage de session`,
  [COUNT_BALE_PLUGIN]: `comptage d'unités`,
  [IGN_TILES_PLUGIN]: `fond de carte IGN`,
  [REAL_TIME_PLUGIN]: `calculs temps réels`,
  [PRINT_MAPS_PLUGIN]: `impression de plans`,
  // [TRACEABILITY_MANAGEMENT_PLUGIN]: `Gérer la traçabilité`,
  [WORKSITE_REPORT_PLUGIN]: `rapport chantier`,
  // [CONTACT_SEC_PLUGIN]: `contact sec`,
  // [BIG_ACCOUNT_PLUGIN]: `grand compte`,
  // [SATELLITE_VEGETATION_PLUGIN]: `Indice de végétation par image satellite`,
  // [DRIVER_REPORT_PLUGIN]: `rapport chauffeur`,
  // [PDF_EXPORT_PLUGIN]: `export pdf`,
  // [AUTOMATED_SHARE_REPORT_PLUGIN]: `rapport partagé automatique`,
  // [MOBILE_OFFLINE_PLUGIN]: `app mobile offline`,
  // [LATENCE_BETWEEN_FIELD_WORK_PLUGIN]: `Délais de réentrée dans les parcelles`,
  // [EDIT_SESSION_PLUGIN]: `editer intervention`,
  // [GO_TO_OBSERVATION_PLUGIN]: `se rendre à une observation`,
  // [VITICULTURAL_CADASTRAL_REFERENCE_PLUGIN]: `référence cadastrale pour les parcelles viticoles`,
  [PHYTO_PLUGIN]: `gestion des phytos`,
  [RPG_ILOT_PLUGIN]: `utilisation du registre des ilots`,
  [LOCK_GEOLOCATION_PLUGIN]: `bloquer la géolocalisation pour les adhérents`,
  [DISABLE_DRIVER_GEOLOC_PLUGIN]: `bloquer la géolocalisation pour les chauffeurs`,
  [WORKED_AREA_PLUGIN]: `affichage de la surface travaillée`
};

export const PLAN_TYPE_GOOD = "good";
export const PLAN_TYPE_BEST = "best";
export const PLAN_TYPE_BEST_PLUS = "best_plus";
export const PLAN_TYPE_BETTER = "better";
export const PLAN_TYPE_BETTER_PLUS = "better_plus";
export const PLAN_TYPE_LEGACY = "legacy";
export const PLAN_TYPE_NONE = "none";
export const PLAN_LABELS = {
  [PLAN_TYPE_NONE]: `Aucun`,
  [PLAN_TYPE_LEGACY]: `Ancien abo`,
  [PLAN_TYPE_GOOD]: `Agri`,
  [PLAN_TYPE_BETTER]: `Pro`,
  [PLAN_TYPE_BETTER_PLUS]: `Pro+`,
  [PLAN_TYPE_BEST]: `Premium`,
  [PLAN_TYPE_BEST_PLUS]: `Premium+`
};
export const JOB_STATUS_LABEL = {
  TODO: "Todo",
  DONE: "Terminé",
  DOING: "En cours",
  ERROR: "Erreur",
  CANCELED: "Annulé"
};
export const JOB_LABEL_LABEL = {
  USER_CREATION: "Création",
  MEETING_FOR_TRAINING: "Rdv formation",
  MONITORING_1: "Tout va bien ?",
  ASSESSMENT: "Rdv bilan",
  NOTATION: "Notation",
  ON_BOARDING: "On board"
};
export const NO_STATE = "null";
export const ONBOARDING_1_STATE = "ONBOARDING_1";
export const ONBOARDING_2_STATE = "ONBOARDING_2";
export const ADOPTION_STATE = "ADOPTION";
export const EXPANSION_STATE = "EXPANSION";
export const ADVOCACY_STATE = "ADVOCACY";
export const CHURN_STATE = "CHURN";
export const GHOST_STATE = "GHOST";
export const TRIAL_STATE = "TRIAL";
export const NOT_CONVERTED_STATE = "NOT_CONVERTED";
export const APPOINTMENT_PLANNED_STATE = "APPOINTMENT_PLANNED";
export const DISTRIBUTOR_STATE = "DISTRIBUTOR";
// => contain all cluster_state
export const CLUSTER_STATE_STATE_LABEL = {
  [ONBOARDING_1_STATE]: ONBOARDING_1_STATE,
  [ONBOARDING_2_STATE]: ONBOARDING_2_STATE,
  [ADOPTION_STATE]: ADOPTION_STATE,
  [EXPANSION_STATE]: EXPANSION_STATE,
  [ADVOCACY_STATE]: ADVOCACY_STATE,
  [DISTRIBUTOR_STATE]: DISTRIBUTOR_STATE,
  [CHURN_STATE]: CHURN_STATE,
  [GHOST_STATE]: GHOST_STATE,
  [TRIAL_STATE]: TRIAL_STATE,
  [NOT_CONVERTED_STATE]: NOT_CONVERTED_STATE,
  [APPOINTMENT_PLANNED_STATE]: "Rendez-vous programmé"
};

// => only cluster_state used as filter or used today
export const CLUSTER_STATE_STATE_LABEL_AS_FILTER = {
  [NO_STATE]: "Aucun état",
  [ONBOARDING_1_STATE]: ONBOARDING_1_STATE,
  [ONBOARDING_2_STATE]: ONBOARDING_2_STATE,
  [ADOPTION_STATE]: ADOPTION_STATE,
  [EXPANSION_STATE]: EXPANSION_STATE,
  [ADVOCACY_STATE]: ADVOCACY_STATE,
  [DISTRIBUTOR_STATE]: DISTRIBUTOR_STATE,
  [CHURN_STATE]: CHURN_STATE,
  [GHOST_STATE]: GHOST_STATE,
  [TRIAL_STATE]: TRIAL_STATE,
  [NOT_CONVERTED_STATE]: NOT_CONVERTED_STATE
};

export const CLUSTER_STATE_CSS_CLASS = {
  [NO_STATE]: "is-dark",
  [ONBOARDING_1_STATE]: "is-light-blue",
  [ONBOARDING_2_STATE]: "is-blue",
  [ADOPTION_STATE]: "is-yellow",
  [EXPANSION_STATE]: "is-light-green",
  [ADVOCACY_STATE]: "is-pink",
  [DISTRIBUTOR_STATE]: "is-light-pink",
  [CHURN_STATE]: "is-orange",
  [GHOST_STATE]: "is-orange",
  [TRIAL_STATE]: "is-green",
  [NOT_CONVERTED_STATE]: "is-grey",
  [APPOINTMENT_PLANNED_STATE]: "is-red"
};

export const CLUSTER_STATE_ICON_CLASS = {
  [NO_STATE]: "restore",
  [ONBOARDING_1_STATE]: "check-circle",
  [ONBOARDING_2_STATE]: "cupcake",
  [ADOPTION_STATE]: "emoticon-cool",
  [EXPANSION_STATE]: "credit-card-multiple",
  [ADVOCACY_STATE]: "thumb-up",
  [DISTRIBUTOR_STATE]: "router-wireless",
  [CHURN_STATE]: "currency-usd-off",
  [GHOST_STATE]: "pause",
  [TRIAL_STATE]: "walk",
  [APPOINTMENT_PLANNED_STATE]: "calendar",
  [NOT_CONVERTED_STATE]: "block-helper"
};

export const DEVICE_HEALTH_UP = "UP";
export const DEVICE_HEALTH_DOWN = "DOWN";
export const DEVICE_HEALTH_CRITICAL = "CRITICAL";

export const DEVICE_MOUNTED_STATUS = "MOUNTED";
export const DEVICE_AVAILABLE_STATUS = "AVAILABLE";
export const DEVICE_IN_USE_STATUS = "IN_USE";
export const DEVICE_PRE_VALIDATED_STATUS = "PRE_VALIDATED";
export const DEVICE_UNMOUNTED_STATUS = "UNMOUNTED";
export const DEVICE_TO_ANALYZE_STATUS = "TO_ANALYZE";
export const DEVICE_MAINTENANCE_STATUS = "MAINTENANCE";
export const DEVICE_STOCK_STATUS = "STOCK";
export const DEVICE_STOCK_REHAB_STATUS = "STOCK_REHAB";
export const DEVICE_STOCK_LENDING_STATUS = "STOCK_LENDING";
export const DEVICE_OUT_OF_SERVICE_STATUS = "OUT_OF_SERVICE";
export const DEVICE_STATUS = [
  DEVICE_MOUNTED_STATUS,
  DEVICE_AVAILABLE_STATUS,
  DEVICE_IN_USE_STATUS,
  DEVICE_PRE_VALIDATED_STATUS,
  DEVICE_UNMOUNTED_STATUS,
  DEVICE_TO_ANALYZE_STATUS,
  DEVICE_MAINTENANCE_STATUS,
  DEVICE_STOCK_STATUS,
  DEVICE_STOCK_REHAB_STATUS,
  DEVICE_STOCK_LENDING_STATUS,
  DEVICE_OUT_OF_SERVICE_STATUS
];

export const BEACON_TYPE_DRIVER = "DRIVER";
export const BEACON_TYPE_EQUIPMENT = "EQUIPMENT";
export const BEACON_TYPES = [BEACON_TYPE_DRIVER, BEACON_TYPE_EQUIPMENT];

export const CLUSTER_TYPE_COLOR = {
  CUMA: "is-danger",
  ETA: "is-info",
  FARM: "is-warning"
};

export const UNDEFINED_DEVICE_STATE_WITH_CLUSTER = "undefined";
export const PURCHASE_DEVICE_STATE_WITH_CLUSTER = "purchase";
export const LENDING_DEVICE_STATE_WITH_CLUSTER = "lending";
export const LOCATION_DEVICE_STATE_WITH_CLUSTER = "location";
export const TEST_DEVICE_STATE_WITH_CLUSTER = "test";

export const DEVICE_STATES_WITH_CLUSTER = [
  UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
  PURCHASE_DEVICE_STATE_WITH_CLUSTER,
  LENDING_DEVICE_STATE_WITH_CLUSTER,
  LOCATION_DEVICE_STATE_WITH_CLUSTER,
  TEST_DEVICE_STATE_WITH_CLUSTER
];

export const DEVICE_STATES_WITH_CLUSTER_FOR_SELECT = [
  PURCHASE_DEVICE_STATE_WITH_CLUSTER,
  LENDING_DEVICE_STATE_WITH_CLUSTER,
  LOCATION_DEVICE_STATE_WITH_CLUSTER,
  TEST_DEVICE_STATE_WITH_CLUSTER
];

export const DEVICE_STATES_WITH_CLUSTER_LABEL = {
  [UNDEFINED_DEVICE_STATE_WITH_CLUSTER]: "Non défini",
  [PURCHASE_DEVICE_STATE_WITH_CLUSTER]: "Achat",
  [LENDING_DEVICE_STATE_WITH_CLUSTER]: "Prêt",
  [LOCATION_DEVICE_STATE_WITH_CLUSTER]: "Location",
  [TEST_DEVICE_STATE_WITH_CLUSTER]: "Test"
};

export const DEVICE_BATTERY_STATUS = {
  OK: "OK",
  WARNING: "WARNING",
  CRITICAL: "CRITICAL"
};

export const KARNOTT_OUTIL_WITH_FALTY_COMPONENTS_RANGES = [
  {
    // commandes 20220806 / Boitier IP68 / Pile 1/2AA
    start: "00862200000000000000000000000F50",
    end: "00862200000000000000000000001143"
  },
  {
    // commandes 020220806-09-1379 / Boitier IP69K / Pile 1/2AA
    start: "03092200000000000000000000001144",
    end: "0309220000000000000000000000152B"
  }
];
